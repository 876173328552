/* App.css */
* {
  box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
}

body {
  margin: 0;
  font-family: 'Arial', sans-serif; /* Updated font family */
  background-color: #e0f7fa; /* Light teal background */
  color: #333; /* Dark text color */
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  min-height: 100vh; /* Full height of the viewport */
  padding: 20px; /* Add some padding */
}

h1 {
  color: #00695c; /* Dark teal for the header */
  margin-bottom: 20px;
  text-align: center; /* Center the header text */
}

.file-upload-container {
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Center items vertically */
  background-color: #ffffff; /* White background for the box */
  border: 1px solid #00796b; /* Border color matching the theme */
  border-radius: 5px; /* Rounded corners */
  padding: 10px; /* Padding inside the box */
  margin-bottom: 20px; /* Space below the box */
}

input[type="file"] {
  margin: 0; /* Reset margin */
  border: none; /* Remove default border */
  flex: 1; /* Take full width of the box */
}

button {
  padding: 10px 20px;
  background-color: #00796b; /* Medium teal background */
  color: white; /* White text */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-left: 10px; /* Space between input and button */
}

button:hover {
  background-color: #004d40; /* Darker teal on hover */
}

p {
  font-size: 16px;
  margin: 10px 0;
  text-align: center; /* Center paragraph text */
  font-weight: bold;
}
